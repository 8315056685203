let colors = [
  "#000000",
  "#9a3400",
  "#333300",
  "#003400",
  "#003400",
  "#000080",
  "#333399",
  "#333333",
  "#810000",
  "#ff6701",
  "#808000",
  "#008000",
  "#008081",
  "#0000ff",
  "#66669a",
  "#808080",
  "#ff0000",
  "#ff9a01",
  "#99cc00",
  "#339967",
  "#33cccc",
  "#3366ff",
  "#800081",
  "#999999",
  "#ff00ff",
  "#ffcc01",
  "#ffff02",
  "#00ff02",
  "#01ffff",
  "#00ccff",
  "#9a3366",
  "#c0c0c0",
  "#ff99cd",
  "#ffcd9a",
  "#ffff99",
  "#cdffcc",
  "#ccffff",
  "#99ccff",
  "#cc99ff",
  "#ffffff",
];

const initEditor = (element, Editor) => {
  Editor.create(element, {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "indent",
        "outdent",
        "|",
        "imageUpload",
        "blockQuote",
        "insertTable",
        "mediaEmbed",
        "fontBackgroundColor",
        "fontColor",
        "fontFamily",
        "fontSize",
        "redo",
        "undo",
        "sourceEditor",
      ],
    },
    language: "zh-cn",
    image: {
      toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    fontSize: {
      options: [
        {
          title: "小",
          model: "0.8em",
        },
        "default",
        {
          title: "大",
          model: "1.4em",
        },
        {
          title: "超大",
          model: "1.8em",
        },
      ],
    },
    fontBackgroundColor: {
      columns: 8,
      colors: colors,
    },
    fontColor: {
      columns: 8,
      colors: colors,
    },
  })
    .then((editor) => {
      window.editor = editor;
    })
    .catch((error) => {
      console.error(error);
    });
};

document.querySelectorAll(".ckeditor").forEach((element) => {
  import("ckeditor5-terraria" /* webpackChunkName: "ckeditor" */).then((res) => {
    initEditor(element, res.default);
  });
});
